import { Flex } from '@chakra-ui/react'
import { Home } from './Home/Home'
import { Header } from './Header/Header'

export const App = () => {
  return (
    <Flex height="100vh" direction="column" flex="1" bg="#f6f8fe">
      <Header />
      <Home />
    </Flex>
  )
}