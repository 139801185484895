import api from '../api';

const voucherService = {
  checkVoucher: async (voucher: string, document: string) => {
    const res = await api
      .post('/voucher/check-voucher', {
        voucher,
        document
      })
      .catch((error) => {
        return error.response;
      });

    return res;
  },
  applyVoucher: async (unity: string, voucher: string, document: string) => {
    const res = await api
      .post('/voucher/apply', {
        unity,
        voucher,
        document
      })
      .catch((error) => {
        return error.response;
      });

    return res;
  },
};

export default voucherService;
