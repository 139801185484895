import { useState } from 'react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Button, Container, Flex, FlexProps, Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react'
import { BiUser } from 'react-icons/bi'
import { FiSearch } from 'react-icons/fi'
import { PiStorefrontDuotone } from 'react-icons/pi'
import { cpfMask } from '../utils/mask'
import voucherService from '../api/services/voucherService'
import './styles.sass'

export const Home = (props: FlexProps) => {
  const [loading, setLoading] = useState(false)
  const [applyLoading, setApplyLoading] = useState(false)
  const [unity, setUnity] = useState('')
  const [voucher, setVoucher] = useState('')
  const [document, setDocument] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')
  const [finish, setFinish] = useState(false)

  const handlechange = (e: any) => {
    setDocument(cpfMask(e.target.value))
  }

  const searchVoucher = async (voucher: string, document: string) => {
    setMessage('')
    setError(false)
    setSuccess(false)
    setFinish(false)

    setLoading(true)

    const { status, data } = await voucherService.checkVoucher(voucher, document)

    if (status === 400) {
      setSuccess(false)
      setError(true)
    } else if (status === 200) {
      setError(false)
      setSuccess(true)
    }

    setMessage(data.message)
    setLoading(false)
  }

  const applyVoucher = async (unity: string, voucher: string, document: string) => {
    setApplyLoading(true)

    const { status, data } = await voucherService.applyVoucher(unity, voucher, document)

    if (status === 400) {
      setSuccess(false)
      setError(true)
    } else if (status === 200) {
      setError(false)
      setSuccess(true)
    }

    setMessage(data.message)
    setApplyLoading(false)
    setFinish(true)
  }

  return (
    <Flex as="main" role="main" direction="column" flex="1" py="16" {...props}>
      <Flex maxW='100%' justifyContent='center' flexWrap='wrap'>
        <h2 className='title'>Valide o cupom aqui</h2>
        
        <Container className='form' mt={6}>
          <Flex className='input' width='100%'>
            <InputGroup>
              <InputLeftElement pointerEvents='none' fontSize='1.4em' height='100%' ml={3}>
                <PiStorefrontDuotone color='gray.300' />
              </InputLeftElement>
              <Select placeholder='Selecione a unidade responsável' size='lg' onChange={(e) => setUnity(e.target.value)} required>
                <option value='MATSUYA MOOCA'>MATSUYA MOOCA</option>
                <option value='Matsuya Santana'>Matsuya Santana</option>
                <option value='MATSUYA PERDIZES'>MATSUYA PERDIZES</option>
                <option value='MATSUYA MORUMBI'>MATSUYA MORUMBI</option>
                <option value='Matsuya Panamby'>Matsuya Panamby</option>
                <option value='MATSUYA VILA MARIANA'>MATSUYA VILA MARIANA</option>
                <option value='MATSUYA -ACLIMACAO'>MATSUYA -ACLIMACAO</option>
                <option value='MATSUYA MOEMA'>MATSUYA MOEMA</option>
                <option value='MATSUYA SAUDE'>MATSUYA SAUDE</option>
              </Select>
            </InputGroup>
          </Flex>

          <Flex className='input' width='100%' mt={5}>
            <InputGroup>
              <InputLeftElement pointerEvents='none' fontSize='1.4em' height='100%' ml={3}>
                <FiSearch color='gray.300' />
              </InputLeftElement>
              <Input type='text' placeholder='Código do cupom' size='lg' onChange={(e) => setVoucher(e.target.value)} />
            </InputGroup>
          </Flex>

          <Flex className='input' width='100%' mt={5}>
            <InputGroup>
              <InputLeftElement pointerEvents='none' fontSize='1.5em' height='100%' ml={3}>
                <BiUser color='gray.300' />
              </InputLeftElement>
              <Input type='tel' maxLength={14} value={document} placeholder='CPF do cliente' size='lg' onChange={handlechange} />
            </InputGroup>
          </Flex>
          {unity !== 'Selecione a unidade responsável' && unity !== '' && 
            <Button
              isLoading={loading}
              width='100%'
              size='lg'
              loadingText='Buscando'
              colorScheme='teal'
              px='35px'
              mt={4}
              onClick={() => searchVoucher(voucher, document)}
            >
              Buscar
            </Button>
          }

          <Flex justifyContent='center' alignContent='center' alignItems='center' flexWrap='wrap' mt={6}>
            {error && <CloseIcon color='red.500' mr={4} />}
            {success && <CheckIcon color='green.400' mr={5} />}
            <h2 className='return'>{message}</h2>
            {(success && !finish) &&
              <Flex w='100%' justify='center' mt={4}>
                <Button
                  isLoading={applyLoading}
                  width='35%'
                  size='md'
                  loadingText='Resgatando cupom...'
                  colorScheme='messenger'
                  px='35px'
                  onClick={() => applyVoucher(unity, voucher, document)}
                >
                  Resgatar cupom
                </Button>
              </Flex>
            }
          </Flex>
        </Container>
      </Flex>
    </Flex>
  )
}